.grid {
    --y:26vh;
    display: grid;
    grid-gap: 10px;
    grid-auto-rows: 29vh;
}
.card-1 .page,.card-3 .page {
    height: var(--y);
    min-height:var(--y);
}
.card-1  .page:nth-of-type(2) {
    height: calc(var(--y) + 3px);
    min-height: calc(var(--y) + 3px);
    flex-direction: column;
    justify-content: space-around;
}
.card-2 .page,.card-4 .page {
    height: calc(28.7vh * 2);
    min-height:calc(28.7vh * 2)
}
@media (max-width:680px) {
    .span-col-3{grid-column: span 1 / auto;}
    .span-col-2{grid-column: span 1 / auto;}
    .span-row-2{grid-row: span 1 / auto;}
    .card-1 .page,.card-2 .page,.card-3 .page,.card-4 .page {
        height: var(--y);
        min-height:var(--y)
    }
}
@media (min-width:680px) {
    .grid {
        grid-template-columns: repeat(3, 1fr);
    }
    .span-col-3{grid-column: span 3 / auto;}
    .span-col-2{grid-column: span 2 / auto;}
    .span-row-2{grid-row: span 2 / auto;}
}
@media (min-width:1200px) {
    .grid {
        grid-template-columns: repeat(6, 1fr);
    }
}
.cardFood {
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center;
    position:relative;
    overflow:hidden;
    border:transparent;
}
.cardProfile {
    position: absolute;
    top: 2%;
    right: 2%;
    width: 5vh;
    height: 5vh;
    border-radius: 50%;
    border: 2px solid white;
}
.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
}
.overlay-container {
    position:absolute;
    display: flex;
    flex-direction: column;
    left:0;
    bottom:5%;
    width:100%;
    justify-content: flex-end;
    align-items: center;
}
.card-4 .overlay-title {
    font-size: 27px;
}
.overlay-title {
    color:white;
    font-weight: 500;
    height: 25%;
    font-size: larger;
    padding: 0 5%;
    display: flex;
    vertical-align: middle;
}
.overlay-description {
    margin: 10px auto;
    width: 90%;
    height: 45%;
}
.overlay-btn {
    height: 25%;
}
.project-btn {
    cursor: pointer;;
    background-color:black;
    padding:8px 20px;
    color:white;
    border:none;
    font-weight:400;
}