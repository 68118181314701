.bottom-align {
    position: absolute;
    bottom: -6%;
}
.baligncontainer {
    position: relative;
}
ul,ol {
    padding-inline-start:20px;
    /* list-style-type: square; */
}
.vertical-divider {
    background-color: black;
    width: 2px;
    margin:0 auto;
    min-height:70px
}
nav {
    position: sticky;
    top:0;
    z-index:2
}
.navbar-logo {
    width: auto;
    height: 100%;
}
.logo-text {
    margin: auto 0;
    font-weight: 600;
}
h4,h3 {
    font-size: auto;
    margin: 0;
}
form {
    margin-top: 0;
}
/* Home */
.feed-btn {
    position: absolute;
    top:-15px;
    right:3.5%;
    z-index: 2;
}
.feed-container {
    position: absolute;
    top:0%;
    right: 0%;
}
/* projectList */
.food-categories {
    display: flex;
    justify-content: space-evenly;
}
/* foodCard */
.card .card-title {
    font-size: auto;
    font-weight: 400;
}
.card-description {
    overflow-y: auto;
}
.card-reveal > .card-title {
    font-size: 2rem;;
}
.card-image>img {
    height: 20vh;
    object-fit: cover;
}
.card .card-content {
    padding: 10px;
    height: 12vh;
}
.card-title.activator {
    width: 95%;
    font-size: auto;
}
/* postCards */
.post-header {
    padding-left: 5%;
    margin-bottom: 3%;
}
.username {
    font-weight: bold;
    margin-bottom: 0;
    text-transform: capitalize;
}
.usertag {
    margin-top: 0;
}
.post-container {
    width: 90%;
    margin: 0 auto;
}
.post-link-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    vertical-align: center;
}
.post-icon {
    cursor: pointer;
    margin-right: 5px;
}
.post-time {
    margin-right: 5%;
}
.profile-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 20px;
}
/* ProjectPage */
.projectImage {
    width: 95%;
    height: auto;
}
.list-item {
    font-size: large;
    margin-bottom: 0;
    padding-bottom: 10px;
}
.authorcite {
    margin: 0 15px;
}
.tagscontainer {
    margin: 10px 0;
}
.chip,.ingredients,.card-title,.user-btn,.helper-text,label {
    text-transform: capitalize;
}
/* profile */
.userProfile-image {
    width: 14rem;
    height: 14rem;
    object-fit: cover;
    margin-top: 10px;
}
/* button loader */
.loaderBtn {
    position: relative;
    overflow-x: hidden;
    width:120px;
    height:60px;
    border:transparent;
    border-radius: 30px;
    background-color:#fae1ba;
    cursor: pointer;
}
.loaderBtn > div {
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: row nowrap;
    align-items: center;
    justify-content: center;
}
.loaderBtn p {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    width:120px;
}
.ballContainer {
    width:120px;
    display:flex;
    flex-flow:row nowrap;
    justify-content:space-evenly;
    margin:0 auto;
    height:100%
}
.ball {
    width:10px;
    height:10px;
    background-color:#9d9d01;
    border-radius:50%;
}
