@font-face {
    font-family: 'Rubik';
    src: url('./Rubik-VariableFont_wght.ttf');
}
html {
    min-height: 100%;
}
body {
  margin: 0;
  padding: 0;
  color: #1d1612;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  letter-spacing: 1px;
}
h1,h2,h3,h4,h5,h6,a {
    font-family: 'Rubik';
}
p {
    font-family: 'Roboto';
    font-weight: 400;
}